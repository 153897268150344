<template>
  <div class="container dynamic-page">
    <!-- 搜索 -->
    <div class="search content">
      <el-form :inline="true" :model="queryForm" class="demo-form-inline">
        <el-row>
          <el-col :span="14">
            <el-form-item>
              <el-select
                v-model="queryForm.termName"
                placeholder="学期"
                style="width: 188px"
                @change="handleChange"
              >
                <el-option
                  v-for="(item, index) in semesterlist"
                  :key="index"
                  :label="item.termName"
                  :value="item.termName"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="queryForm.auditStatus"
                placeholder="状态"
                style="width: 95px"
                @change="handleStatus"
              >
                <el-option label="全部状态" value=""></el-option>
                <el-option label="审核中" value="0"></el-option>
                <el-option label="已通过" value="1"></el-option>
                <el-option label="被驳回" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10" style="text-align: right">
            <el-form-item>
              <el-button type="warning" @click="handleRelease">发布</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <!-- 动态 -->
    <div class="dynamic content" v-for="(item, index) in dynamic" :key="index">
      <div class="avatar">
        <el-avatar
          v-if="item.userInfo[0] && item.userInfo[0]!='null'"
          :src="imgSrc + item.userInfo[0]"
        ></el-avatar>
        <el-avatar
          v-else
          src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
        ></el-avatar>
        <div class="info">
          <h4>{{ item.userInfo[1] }}</h4>
          <p>{{ item.createTime.dateStr }} {{ item.createTime.time }}</p>
          <div class="status">
            <img
              v-if="item.auditStatus === '0'"
              src="../../../../assets/image/wei-shenhe.png"
              alt=""
            />
            <img
              v-if="item.auditStatus === '1'"
              src="../../../../assets/image/yi-tongguo.png"
              alt=""
            />
            <img
              v-if="item.auditStatus === '2'"
              src="../../../../assets/image/wei-tongguo.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="article">
		<!-- 文字内容 -->
        <div class="art-cont" v-html="item.content"></div>
		<!-- 图片内容 -->
        <div class="art-image">
			<media-exhi v-if="item.imagesUrl" :file-data="item.imagesUrl"></media-exhi>
        </div>
        <div class="art-kind">
          <div class="kind kindC">
            {{item.indexOneName}} - {{ item.indexThreeName }}
          </div>
          <div class="operation">
            <el-link
              v-if="item.auditStatus === '2'"
              class="see"
              :underline="false"
              @click="handleReject(item)"
              >查看被驳回原因</el-link
            >
            <span class="padd"  v-if="item.auditStatus === '2'">|</span>
            <el-link
              v-if="item.auditStatus === '2'"
              class="see"
              :underline="false"
              @click="handleRewrite(item)"
              >重新编辑</el-link
            >
            <span v-if="item.auditStatus === '2'" class="padd">|</span>
            <span @click="handleRemoveCord(item)"
              ><i class="el-icon-delete" style="font-size: 16px"></i
            ></span>
            <span class="padd">|</span>
            <span
              :class="{ dianzan: item.isDianZan !== '0' }"
              @click="handleDianzan(item)"
              ><i class="iconfont icon-dianzan"></i> {{ item.dianZanNum }}</span
            >
            <span class="padd">|</span>
            <span @click="openComment(item, index, {}, -1,  1)" ref="showInput"
              ><i class="iconfont icon-tubiaozhizuomoban"></i
            ></span>
          </div>
        </div>
        <!-- 评论内容 -->
       <div class="discuss" v-if="item.comments.length > 0">
          <ul>
            <li v-for="(el, elin) in item.comments" :key="elin">
				<div class="avatar">
					<el-image
					  v-if="el[1] && el[1]!='null'"
					  :src="imgSrc + el[1]"
					  fit="cover"
					  style="width: 40px;height: 40px;"
					></el-image>
					<el-image
					  v-else
					  src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
					  fit="cover"
					  style="width: 40px;height: 40px;"
					></el-image>
				</div>
              <div class="info">
                <h4>
                  <span>{{ el[2] }}</span
                  >：{{ el[4] }}
                </h4>
                <div style="display: flex; align-items: center">
                  <p>{{ el[3] ? el[3].dateStr : '' }} {{ el[3] ? el[3].time : '' }}</p>
                  <el-button v-if="is_self(el[0])"  size="mini" type="text" style="margin-left: 10px; color: #999999" @click="removeComment(item, index, el, elin, 1)">删除</el-button>
				  <el-button size="mini" type="text" style="margin-left: 10px; color: #999999"  ref="showInput" @click="openComment(item, index, el, elin, 2)">回复</el-button>
                </div>
				<!-- 2级评论 -->
				<div class="comments-2th" v-if="show2thCmtZone(el)">
					<ul>
					  <li v-for="(cmt, cmtIdx) in el[6].list" :key="cmtIdx">
						<div class="avatar">
							<el-image
							  v-if="cmt[1] && cmt[1]!='null'"
							  :src="imgSrc + cmt[1]"
							  fit="cover"
							  style="width: 40px;height: 40px;"
							></el-image>
							<el-image
							  v-else
							  src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
							  fit="cover"
							  style="width: 40px;height: 40px;"
							></el-image>
						</div>
					    <div class="info">
					      <h4>
					        <span>{{ cmt[2] }}</span
					        >：{{ cmt[4] }}
					      </h4>
					      <div style="display: flex; align-items: center">
					       <p>{{ cmt[3] ? cmt[3].dateStr : '' }} {{ cmt[3] ? cmt[3].time : '' }}</p>
					        <el-button v-if="is_self(cmt[0])"  size="mini" type="text" style="margin-left: 10px; color: #999999" @click="removeComment(item, index, el, elin, 2, cmt, cmtIdx)">删除</el-button>
							<el-button size="mini" type="text" ref="showInput" style="margin-left: 10px; color: #999999" @click="openComment(item, index, el, elin, 3, cmt, cmtIdx)">回复</el-button>
					      </div>
					    </div>
					  </li>
					</ul>
					<span class="see-more-cmts" v-if="showLoadMore2ndCmt(el)" @click="getMore2ndComments(item, index, el, elin)"><i class="el-icon-plus"></i>查看更多回复</span>
				</div>
				
              </div>
            </li>
          </ul>
		  <span class="see-more-cmts" @click="getMore1stComments(item, index)" v-if="showLoadMoreCmt(item, index)"><i class="el-icon-plus"></i>查看更多评论</span>
        </div>
        <!-- 打开评论 -->
        <div class="comment">
          <div class="comment-cont" ref="showInput" v-if="item.isComments">
            <div class="comment-text">
              <el-form
                :model="form"
                :rules="rules"
                ref="commentsForm"
                class="demo-ruleForm"
              >
                <el-form-item prop="commentContent">
                  <el-input
                    type="textarea"
                    :rows="3"
                    v-model="commentContent"
					:placeholder="commentPh"
                    resize="none"
                    v-focus
					:id="`cometEditor${index}`"
                  >
                  </el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="comment-btn">
              <el-popover placement="left-end" v-model="showEmoji">
				<template>
					<ul class="emoji-list">
						<li v-for="(item,idx) in emojiList" :key="idx" @click="inputEmoji(idx, index)">{{ item.char }}</li>
					</ul>
				</template>
				<span slot="reference" class="iconfont icon-xiaolian" style="font-size: 20px;"></span>
              </el-popover>
			  <div>
				  <el-button type="primary" v-if="cmtMultiGrade.grade == 1" @click="submitComments(item, index)">评论</el-button>
				  <el-button type="text" v-if="cmtMultiGrade.grade != 1" @click="openComment(item, index, {}, -1,  1)">切换至评论</el-button>
				  <el-button type="success" v-if="cmtMultiGrade.grade != 1" @click="submitReply(item.id)">回复</el-button>
			  </div>
            </div>
          </div>
          <div class="comment-none" @click="openComment(item, index, {}, -1, 1)" v-else>
            评论
          </div>
        </div>
      </div>
    </div>

    <!-- 发布 -->
    <el-dialog title="发布个人写实记录" :visible.sync="open" width="600px" center :close-on-click-modal="false" :destroy-on-close="true" @opened="initEditor">
      <el-form :model="form" :rules="rules" ref="form" class="demo-ruleForm">
        <el-form-item prop="content" label="内容：">
          <div id="editor" ref="editor"></div>
        </el-form-item>
        <el-form-item label="附件：">
          <el-upload
            :action="upload.action"
            :accept="upload.accept"
            :limit="upload.limit"
            :multiple="upload.multiple"
            :file-list="upload.list"
            :on-change="handleUpChange"
            :on-remove="handleRemove"
            :auto-upload="upload.auto"
          >
            <el-button size="small" type="primary" v-if="upload.list.length < 3">添加附件</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="标签选择" prop="indexThreeId">
          <el-select
            v-model="form.indexOneId"
            placeholder="一级指标"
            style="width: 100px"
            @change="getIndexTwo"
          >
            <el-option
              v-for="(item, index) in indexOne"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="form.indexThreeId"
            placeholder="二级指标"
            style="width: 200px; margin-left: 15px"
            @change="getIndexTwoId"
          >
            <el-option
              v-for="(item, index) in indexTwo"
              :key="index"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
		  <el-button size="small" type="text" style="margin-left: 20px;" :disabled="!form.indexThreeId" @click="getExample">查看示例</el-button>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancle">取 消</el-button>
        <el-button type="primary" @click="handleSubmit">提交</el-button>
      </div>
    </el-dialog>

    <!-- 驳回原因 -->
    <el-dialog
      class="reject-box"
      title="驳回原因"
      :visible.sync="openReject"
      width="33%"
      center
    >
      <div class="dialog-name">
        <p>驳回原因：{{ rejectContent }}</p>
		<p>驳回者：{{ rejectPeople }}</p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="openReject = false">关 闭</el-button>
      </span>
    </el-dialog>
	
	<!-- 写实记录示例 -->
	<el-dialog
	title="示例详情"
	:visible.sync="dialog.example"
	width="600px"
	center>
		<el-image :src="example.url" fit="cover" :preview-src-list="example.preview"></el-image>
		<p style="text-align: right; color: #999; font-size: 12px;">点击图片可放大</p>
		<div slot="footer">
			<el-button type="primary" @click="closeDialog('example')">关闭</el-button>
		</div>
	</el-dialog>
  </div>
</template>

<script>
import {
  getIndex,
  getIndexThree,
  addRecord,
  getAllSemester,
  getDianZan,
  addComments,
  getComments,
  getMyrease,
  removeMyrease,
  removeComments,
  getExample,
  cmt2cmt
} from "@/api/record/personal/personal"
import E from "wangeditor"
import { getToken } from "@/utils/auth"
import mediaExhi from "@/components/mediaExhibition.vue"
import { dynamicFn } from "@/mixin/dynamic/index.js"
import { dynamicStuPersonal } from "@/mixin/dynamic/personal/student.js"
export default {
	components: {
		mediaExhi
	},
	mixins: [ dynamicFn, dynamicStuPersonal ],
  data() {
    return {
      queryForm: {
        pageNum: 1,
        pageSize: 15,
        kind: 0,
        auditStatus: "",
        termName: "",
      },
	  pageTotal: 0,
      open: false,
      openReject: false,
      comment: "",
      rejectContent: "",
	  rejectPeople: "",
      semesterlist: [],
      dynamic: [],
      indexOne: [],
      indexTwo: [],
      indexThree: [],
	  dialog: {
	  	example: false
	  },
	  example: {
	  		url: '',
			preview: []
	  },
	  upload: {
		action: '',
		accept: '.jpg,.png,.doc,.docx,.xls,.xlsx,.pdf,.txt',
		limit: 3,
		multiple: false,
		list: [],
		auto: false
	  },
      form: {
        indexRuleLevel: "0",
        file: [],
      },
      rules: {
        indexThreeId: [
          { required: true, message: "请选择指标标签", trigger: "change" },
        ],
        // commentContent: [
        //   { required: true, message: "请输入内容或表情", trigger: "blur" },
        // ],
      },
      commentContent: '',
	  commentPh: '',
      isRewrite: false,
	  editor: {},
	  // 写实记录字数
	  contentLen: {
		max: 500,
		min: 50
	  }
    }
  },
  created() {
    this.semester();
  },
  mounted() {
    document.addEventListener("click", (e) => {
      if (this.$refs.showInput) {
        let isSelf;
        let showArr = this.$refs.showInput
        for (let i = 0; i < showArr.length; i++) {
			let v = showArr[i].$el ? showArr[i].$el : showArr[i]
          if (v.contains(e.target)) {
            isSelf = v.contains(e.target)
          }
        }
        if (!isSelf) {
          this.commentContent = '';
          this.dynamic.map((item) => {
            item.isComments = false;
          });
		  this.showEmoji = false
        }
      }
    })
	document.addEventListener("scroll", this.Scrollbottom);
  },
  methods: {
	// 写实记录示例
	getExample() {
		getExample({
			threeIndexId: this.form.indexThreeId
		}).then(res => {
			if (res.data) {
				this.example.url = this.imgSrc + res.data.fileUrl
				this.example.preview.push(this.example.url)
				this.openDialog('example')
			} else {
				this.$message.info('暂无示例图片')
			}
		})
	},
	// 打开弹窗
	openDialog(nm) {
		this.dialog[nm] = true
	},
	// 关闭弹窗
	closeDialog(nm) {
		this.dialog[nm] = false
	},
    getList() {
      getMyrease(this.queryForm).then((res) => {
		this.pageTotal = res.data.pages;
        this.dynamic = res.data.list
        this.dynamic.map(async (item, idx) => {
          item.createTime = this.splitDate(item.createTime)
          item.isComments = item.isComments == "0" ? false : true
          item.userInfo = item.userInfo.split(",")
          item.comments = await this.process1stCommts(item, idx, [], [])
        })
      })
    },
	getListCount() {
	  getMyrease(this.queryForm).then((res) => {
	    let arr = res.data.list;
	    arr.map(async (item, idx) => {
	      item.createTime = this.splitDate(item.createTime);
	      item.isComments = item.isComments == "0" ? false : true;
	      item.userInfo = item.userInfo.split(",")
	      item.comments = await this.process1stCommts(item, idx, [], [])
		  this.dynamic.push(item)
	    })
	  })
	},
    // 发布
    handleRelease() {
      this.open = true;
      this.isRewrite = false
	  this.upload.list = []
      this.reset()
      this.getIndexOne();
    },
    // 上传文件
    handleUpChange(file, fileList) {
      let rawTp = file.raw.type
      // pdf, excel表格, word文档, 图, 纯文本
      let allowTp = ['application/pdf', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword', 'image/gif', 'image/png', 'image/jpeg', 'text/plain']
      if (allowTp.indexOf(rawTp) == -1) {
      	this.$message.warning("附件仅支持pdf、excel表格、word、图片与纯文本格式！")
      	fileList.pop()
      } else if(file.size / 1024 > 5 * 1024) {
        this.$message.warning('附件大小不得超过5MB')
        fileList.pop()
      } else {
      	this.upload.list.push(file)
      	this.$message.success("上传成功")
      }
    },
	// 删除附件
    handleRemove(file, fileList) {
      this.upload.list = fileList
    },
    handleRewrite(row) {
      this.reset()
      this.getIndexOne()
      this.getIndexTwo(row.indexOneId)
      this.getIndexThree(row.indexTwoId)
      this.form = JSON.parse(JSON.stringify(row))
      this.isRewrite = true
      this.open = true
    },
	// 发布
    async handleSubmit() {
		let cont = this.editor.txt.text()
		if (cont.length < this.contentLen.min) {
			this.$message.warning(`发布内容至少输入${ this.contentLen.min }个字`)
			return
		} else if (cont.length > this.contentLen.max) {
			this.$message.warning(`发布内容字数不得超过${ this.contentLen.max }字，请知悉！`)
			return
		}
		var fd = new FormData()
		// 包含附件
		if (this.upload.list.length > 0) {
			let imgFile = this.upload.list.filter(v => (/image\//g).test(v.raw.type))
			let otherFile = this.upload.list.filter(v => !(/image\//g).test(v.raw.type))
			// 附件包含图片
			if (imgFile.length > 0) {
				let temp = await this.compressImg(imgFile)
				temp.forEach(v => {
					fd.append('file', v)
				})
			}
			// 附件包含其它格式
			if (otherFile.length > 0) {
				otherFile.forEach(v => {
					fd.append('file', v.raw)
				})
			}
		}
      setTimeout(()=>{
        this.$refs["form"].validate((valid) => {
          if (valid) {
            fd.append("content", this.editor.txt.html());
            fd.append("indexOneId", this.form.indexOneId);
            fd.append("indexTwoId", this.form.indexTwoId);
            fd.append("indexThreeId", this.form.indexThreeId);
            fd.append("indexThreeName", this.form.indexThreeName);
            fd.append("indexRuleLevel", this.form.indexRuleLevel);
            if(this.isRewrite) {
              fd.append("id", this.form.id)
            }
            addRecord(fd).then((res) => {
              this.$message.success("发布成功");
			  this.upload.list = []
              this.cancle();
              this.getList();
              this.resetForm("form");
            });
          }
        });
      },1000)
    },
    // 点赞
    handleDianzan(item) {
      getDianZan({ id: item.id }).then((res) => {
        if (item.isDianZan == "0") {
          item.isDianZan = "1"
          item.dianZanNum++
        } else {
          item.isDianZan = "0"
          item.dianZanNum--
        }
      })
    },
    // 查看驳回原因
    handleReject(item) {
      this.openReject = true;
      this.rejectContent = item.causeDes
	  this.rejectPeople = item.updateBy
    },
    is_self(id) {
      return id == this.$store.getters.userId
    },
    // 删除写实记录
    handleRemoveCord(item, idx) {
      this.$confirm("删除该条写实记录后，对应的综素得分将被扣除，确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeMyrease({ id: item.id }).then((res) => {
            this.$message.success("删除写实记录成功");
            this.dynamic.splice(idx, 1)
          });
        })
        .catch(() => {
          return false;
        });
    },
    // 重新获取列表
    handleChange(termName) {
      this.queryForm.termName = termName;
      this.getList();
    },
    handleStatus(val) {
      this.queryForm.auditStatus = val;
      this.getList();
    },
    cancle() {
      this.open = false;
      this.reset()
    },
    reset() {
      this.form = {
        content: undefined,
        indexOneId: undefined,
        indexTwoId: undefined,
        indexThreeId: undefined,
        indexThreeName: undefined,
        indexRuleLevel: 0,
        file: [],
        fileList: [],
      };
	  this.upload.list = []
      this.resetForm("form");
    },
    // 获取学期
    semester() {
      getAllSemester({}).then((res) => {
        this.semesterlist = res.data;
        this.queryForm.termName = this.semesterlist[0].termName;
        this.getList();
      });
    },
    // 获取指标
    getIndexOne(id = 0) {
      getIndex({ id: id }).then((res) => {
        this.indexOne = res.data;
      });
    },
    getIndexTwo(id) {
      this.form.indexTwoId = ''
      this.form.indexThreeId = ''
      getIndex({ id: id }).then((res) => {
        this.indexTwo = res.data;
      });
    },
	// 获取3级指标后取二级指标id
	getIndexTwoId(v) {
		this.indexTwo.forEach(item => {
			if (item.code == v) {
				this.form.indexThreeName = item.name
				this.form.indexTwoId = item.parentId
			}
		})
	},
    getIndexThree(id) {
      this.form.indexThreeId = ''
      getIndexThree({ id: id }).then((res) => {
        this.indexThree = res.data;
        if(this.indexThree.length < 1) {
          this.$message.warning('该指标项下没有写实记录，请更换二级指标')
        }
      });
    },
    getIndexThreeName(id) {
      this.indexThree.forEach((item) => {
        if (item.id == id) {
          this.form.indexThreeName = item.name;
        }
      });
    },
	Scrollbottom () {
	  let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
	  let clientHeight = document.documentElement.clientHeight;
	  let scrollHeight = document.documentElement.scrollHeight;
	  if (scrollTop + clientHeight >= scrollHeight - 300) {
	    if(this.queryForm.pageNum < this.pageTotal) {
	      this.queryForm.pageNum ++
	      this.getListCount()
	    }
	  }
	},
	// 初始化编辑器
	initEditor() {
		var that = this;
		// 销毁已有editor实例
		if (!that.isEmpty(that.editor.id)) {
			that.editor.destroy()
			that.editor = null
		}
		that.editor = new E(that.$refs.editor)
		// 编辑器的事件，每次改变会获取其html内容
		that.editor.config.onchange = (html) => {
		  that.form.content = html
		}
		that.editor.config.height = 300;
		that.editor.config.showLinkImg = false;
		that.editor.config.debug = true;
		that.editor.config.uploadImgServer =
		  process.env.VUE_APP_API + "/shida-user-biz/upload/uploadImage";
		that.editor.config.uploadFileName = "file";
		that.editor.config.placeholder = "请输入写实记录内容"
		that.editor.config.uploadImgHeaders = {
		  Authorization: getToken(),
		  'role-distinction-client': 2
		};
		that.editor.config.uploadImgHooks = {
		  // 图片上传并返回结果，但图片插入错误时触发
		  fail: function (xhr, editor, result) {
		    console.error(result);
		  },
		  success: function (xhr, editor, result) {
		    // 图片上传并返回结果，图片插入成功之后触发
		  },
		  // 图片上传并返回了结果，想要自己把图片插入到编辑器中
		  // 例如服务器端返回的不是 { errno: 0, data: [...] } 这种格式，可使用 customInsert
		  customInsert: function (insertImgFn, result) {
		    // result 即服务端返回的接口
		    // insertImgFn 可把图片插入到编辑器，传入图片 src ，执行函数即可
		    insertImgFn(that.imgSrc + result.data);
		  },
		};
		that.editor.config.menus = [
		  // 菜单配置
		  "bold", // 粗体
		  "fontSize", // 字号
		  "italic", // 斜体
		  "underline", // 下划线
		  "strikeThrough", // 删除线
		  "foreColor", // 文字颜色
		  "backColor", // 背景颜色
		  "link", // 链接
		  "undo", // 撤销
		  "redo", // 重做
		];
		that.editor.create(); // 创建富文本实例
		if (that.isRewrite) {
			that.editor.txt.html(that.form.content)
		}
	}
  },
};
</script>
<style scoped>
.el-select >>> .el-input--suffix .el-input__inner {
  padding-left: 5px !important;
}
.el-textarea >>> .el-textarea__inner:focus {
  border: 1px solid #dcdfe6;
}
.el-textarea >>> .el-input__count {
  background: rgba(255, 255, 255, 0);
}
.el-form >>> .el-upload--picture-card {
  width: 100px;
  height: 100px;
  line-height: 100px;
}
.el-form >>> .el-upload-list--picture-card .el-upload-list__item {
  width: 100px;
  height: 100px;
}
#editor {
	clear: both;
}
</style>